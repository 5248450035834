export default [
  {
    key: "lead_name",
    label: "Client",
    visible: true,
    sortable: false,
    thStyle: { width: "230px" },
  },
  {
    key: "payment_type",
    label: "Payment Type",
    visible: true,
  },
  // {
  //   key: 'date_activation',
  //   label: 'App',
  //   visible: true,
  //   sortable: false,
  //   class: 'text-center',
  // },
  {
    key: "balance",
    label: "Balance",
    visible: true,
    sortable: false,
  },
  {
    key: "monthly_amount",
    label: "Amount",
    visible: true,
    sortable: false,
  },
  {
    key: "last_payment",
    label: "LP",
    visible: true,
    sortable: false,
    class: "text-center",
  },
  // {
  //   key: 'type_payment',
  //   label: 'PT',
  //   visible: true,
  //   sortable: false,
  //   class: 'text-center',
  // },
  // {
  //   key: 'score',
  //   label: 'Score',
  //   visible: true,
  //   sortable: false,
  // },
  // {
  //   key: 'goals',
  //   label: 'Goals',
  //   visible: true,
  // },
  // {
  //   key: 'advisor_name',
  //   label: 'Advisor',
  //   visible: true,
  // },

  // {
  //   key: 'typedoc',
  //   label: 'Type Doc',
  //   visible: true,
  //   class: 'text-center',
  // },
  // {
  //   key: 'date_income',
  //   label: 'Last Date Income',
  //   visible: true,
  //   sortable: false,
  //   class: 'text-center',
  // },
  {
    key: "created_at",
    label: "Enroll Date",
    visible: true,
    sortable: false,
    class: "text-center",
  },

  {
    key: "client_state",
    label: "ST",
    visible: true,
  },
  {
    key: "sms",
    label: "SMS",
    visible: true,
  },
  {
    key: "fromm",
    label: "From",
    sortable: true,
    visible: true,
  },
];
