export default [

  {
    type: 'datepicker',
    margin: true,
    label: '(Last Date Income) From',
    showLabel: true,
    placeholder: 'Date Income',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'datepicker',
    margin: true,
    label: '(Last Date Income) To',
    showLabel: true,
    placeholder: 'Date Income',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Advisor',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'text',
    cols: 12,
    visible: true,
  },
  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
    visible: false,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Payment Type',
    model: null,
    options: [
      { id: 0, text: 'All' },
      { id: 1, text: 'Automatic' },
      { id: 2, text: 'Manual' },
      { id: 3, text: 'Others' },
    ],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Payment Day',
    model: null,
    options: [
      { value: 0, label: 'All' },
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 20, label: '20' },
      { value: 25, label: '25' },
      { value: 30, label: '30' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
    visible: false,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Score',
    model: null,
    options: [
      { id: null, text: 'All' },
      { id: 0, text: '0' },
      { id: 1, text: '300-400' },
      { id: 2, text: '401-500' },
      { id: 3, text: '500-600' },
      { id: 4, text: '600-650' },
      { id: 5, text: '651-700' },
      { id: 6, text: '701-750' },
      { id: 7, text: '751-800' },
      { id: 8, text: '801-850' },
    ],
    reduce: 'id',
    selectText: 'text',
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Type Doc',
    model: null,
    options: [
      { text: 'All', value: 0 },
      { text: 'SSN', value: 'ssn' },
      { text: 'ITIN', value: 'itin' },
      { text: 'CPN', value: 'other' },
    ],
    reduce: 'id',
    selectText: 'text',
    cols: 5,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Goals',
    model: null,
    options: [
      { text: 'CASA', value: 'goal-1' },
      { text: 'AUTO', value: 'goal-2' },
      { text: 'REFINANCIAR CASA', value: 'goal-3' },
      { text: 'REFINANCIAR AUTO', value: 'goal-4' },
      { text: 'EXCELENTE CREDITO', value: 'goal-5' },
    ],
    reduce: 'value',
    selectText: 'text',
    cols: 7,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'State of US',
    model: null,
    options: [],
    reduce: 'slug',
    selectText: 'state',
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'App Activated',
    model: null,
    options: [{ text: 'All', value: '' }, { text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
    reduce: 'value',
    selectText: 'text',
    cols: 6,
    visible: true,
  },
]
