<template>
  <div>
    <ClientsTable :statusSearch="2" />
  </div>
</template>

<script>
import ClientsTable from '@/views/ce-digital/sub-modules/financial/view/clients/components/ClientsTable.vue'

export default {
  components: {
    ClientsTable,
  },
}
</script>

<style>

</style>
