<template>
  <div class="pl-1 pt-1">
    <b-nav v-if="statusSearch === 1" card-header pills class="m-0">
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 11 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 11"
        @click="changeNav(11)"
      >
        In Progress
        <span v-if="G_CLIENTS_COUNTER_IN_PROGRESS > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_IN_PROGRESS"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 12 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 12"
        @click="changeNav(12)"
      >
        Potential
        <span v-if="G_CLIENTS_COUNTER_POTENTIAL > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_POTENTIAL"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 13 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 13"
        @click="changeNav(13)"
      >
        Stand By
        <span v-if="G_CLIENTS_COUNTER_STAND_BY > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_STAND_BY"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
    </b-nav>
    <b-nav v-else card-header pills class="m-0">
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 2 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 2"
        @click="changeNav(2)"
      >
        Hold
        <span v-if="G_CLIENTS_COUNTER_HOLD > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_HOLD"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 4 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 4"
        @click="changeNav(4)"
      >
        Canceled
        <span v-if="G_CLIENTS_COUNTER_CANCELED > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_CANCELED"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 6 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 6"
        @click="changeNav(6)"
      >
        Closed
        <span v-if="G_CLIENTS_COUNTER_CLOSED > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_CLOSED"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        :change-columns-by-client="true"
        @reload="$refs.refClientsList.refresh()"
        @reset-all-filters="updateTable"
      >
        <template #buttons>
          <!-- Button refresh -->
          <b-button
            v-if="isCeo || isSupervisor"
            variant="success"
            class="ml-1"
            :disabled="pnttime"
            @click="contador"
          >
            <feather-icon icon="RefreshCcwIcon" class="mr-50" />{{
              pnttime == false
                ? "Refresh"
                : tiempominutos + ":" + tiemposegundos + " sec"
            }}
          </b-button>
        </template>
        <template #table>
          <b-table
            slot="table"
            ref="refClientsList"
            small
            no-provider-filtering
            :items="myProvider"
            :fields="visibleFields"
            primary-key="id"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(lead_name)="data">
              <div style="width: 200px">
                <router-link
                  :class="[textLink]"
                  :to="{
                    name: 'financial-clients-account',
                    params: {
                      idClient: data.item.account_id,
                    },
                  }"
                  target="_blank"
                  >{{ data.item.lead_name }}
                </router-link>
                <span v-if="data.item.user_responsible" class="text-warning">
                  <tabler-icon
                    :icon="
                      data.item.user_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'
                    "
                    size="15"
                  />
                  <strong>CEO's client</strong>
                </span>
                <div>
                  <span>{{ data.item.account }}</span>
                  <status-account :account="data.item" />
                </div>
                <div>
                  <span>{{ data.item.eeuustate }} </span>
                  <div
                    v-if="
                      data.item.in_connection == 'ADMINISTRATION' &&
                      data.item.status_connection == 'TRANSITION'
                    "
                  >
                    <span style="position: relative">
                      <div class="status_circle bg-info" />
                    </span>
                    <span style="margin-left: 15px">Administration</span>
                    <br />
                  </div>
                  <div
                    v-if="
                      data.item.in_connection == 'ORIGIN' &&
                      data.item.status_connection == 'REJECTED'
                    "
                    style="position: relative"
                  >
                    <span style="position: relative">
                      <div class="status_circle bg-danger" />
                    </span>
                    <span style="margin-left: 15px">Rejected</span>
                    <br />
                  </div>
                  <div
                    v-if="data.item.in_connection == 'CONNECTION'"
                    style="position: relative"
                  >
                    <span style="position: relative">
                      <div class="status_circle bg-success" />
                    </span>
                    <span style="margin-left: 15px">Connection</span>
                    <br />
                  </div>
                  <span> {{ data.item.mobile }}</span>
                </div>
              </div>
            </template>
            <template #cell(payment_type)="data">
              <div class="text-center">
                <div class="d-flex pt-1 pl-2">
                  <img
                    v-if="
                      data.item.type_payment == 'PA' &&
                      data.item.status_payment == '1' &&
                      data.item.srb == 'ACTIVE'
                    "
                    :title="'Automatic/' + data.item.srb"
                    :src="'/assets/images/social-network/paymentType/paymenttype-auto.ico'"
                    alt=""
                  />
                  <img
                    v-if="
                      data.item.type_payment == 'PA' &&
                      data.item.status_payment == '0' &&
                      data.item.srb != 'ACTIVE'
                    "
                    :title="'Automatic/' + data.item.srb"
                    :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
                    alt=""
                  />
                  <img
                    v-if="
                      data.item.type_payment == 'PA' &&
                      data.item.status_payment == '1' &&
                      data.item.srb != 'ACTIVE'
                    "
                    :title="'Automatic/' + data.item.srb"
                    :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
                    alt=""
                  />
                  <img
                    v-if="data.item.type_payment == 'MA'"
                    title="Manual"
                    :src="'/assets/images/social-network/paymentType/paymenttype-manual.ico'"
                    alt=""
                  />
                  <img
                    v-if="data.item.type_payment == 'OT'"
                    title="Others"
                    :src="'/assets/images/social-network/paymentType/paymenttype-others.ico'"
                    alt=""
                  />
                  <span v-if="data.item.day_payment != null">
                    ({{ data.item.day_payment }})
                  </span>
                </div>
              </div>
            </template>
            <template #cell(balance)="data">
              <div class="text-center d-flex pt-1">
                <div variant="outline" class="m-0 p-0 pt-0">
                  <span
                    style="color: #f74f4f; text-align: center"
                    class="cursor-pointer"
                    :class="[
                      data.item.balance < 0 ? 'text-danger' : 'text-success',
                    ]"
                    >$ {{ data.item.balance | currencyZero }}</span
                  >
                </div>
              </div>
            </template>
            <!-- Column App -->
            <template #cell(date_activation)="data">
              <div
                style="text-align: center"
                class="text-bold"
                :class="
                  data.item.date_activation != null
                    ? 'text-success'
                    : 'text-info'
                "
              >
                {{ data.item.date_activation ? "Activated" : "No" }}
                <br />
                {{ data.item.date_activation | myGlobalDay }}
              </div>
            </template>
            <!-- Column MP -->
            <template
              v-if="
                (moduleId != 2 && moduleId != 6) ||
                (moduleId == 6 && currentUser.role_id != 4)
              "
              #cell(monthly_amount)="data"
            >
              $ {{ data.item.monthly_amount }}
            </template>
            <!-- Column LP -->
            <template
              v-if="
                (moduleId != 2 && moduleId != 6) ||
                (moduleId == 6 && currentUser.role_id != 4)
              "
              #cell(last_payment)="data"
            >
              <LastPaymentItem :client-account-id="data.item.account_id" />
            </template>
            <template #cell(fromm)="data">
              {{ data.item.fromm | myGlobal }}
            </template>
            <!-- Column PT -->
            <template #cell(type_payment)="data">
              <status-payment :account="data.item" />
            </template>
            <!-- column SCORE -->
            <template #cell(score)="data">
              <div>
                <b-badge
                  v-if="data.item.score"
                  style="width: 50px"
                  pill
                  :variant="
                    data.item.score <= 659 && !data.item.score
                      ? 'danger'
                      : data.item.score >= 660 && data.item.score <= 699
                      ? 'warning'
                      : data.item.score >= 700 && data.item.score <= 759
                      ? 'primary'
                      : data.item.score >= 760 && data.item.score <= 850
                      ? 'success'
                      : 'danger'
                  "
                >
                  {{ data.item.score }}
                </b-badge>
              </div>
            </template>
            <template #cell(sms)="data">
              <div class="d-flex align-items-center">
                <div
                  v-if="
                    data.item.user_responsible == currentUser.user_id ||
                    data.item.user_responsible == null
                  "
                  class="mr-1"
                >
                  <feather-icon
                    size="20"
                    icon="MessageSquareIcon"
                    class="text-primary cursor-pointer"
                    @click="openAddSmsModal(data.item, 2)"
                  />
                </div>
                <div>
                  <feather-icon
                    size="20"
                    icon="ListIcon"
                    class="text-primary cursor-pointer"
                    @click="openSmsHistoryModal(data.item.account_id)"
                  />
                </div>
              </div>
            </template>
            <!-- column GOALS -->
            <template #cell(goals)="data">
              <div>
                <ul style="padding-left: 0px; margin-bottom: 0px">
                  <template v-if="Array.isArray(data.item.answer_goals)">
                    <li
                      v-for="(goals, index) in data.item.answer_goals"
                      :key="index"
                      style=""
                    >
                      <span>{{
                        goals.id == "goal-1"
                          ? "CASA"
                          : goals.id == "goal-2"
                          ? "AUTO"
                          : goals.id == "goal-3"
                          ? "REFINANCIAR CASA"
                          : goals.id == "goal-4"
                          ? "REFINANCIAR AUTO"
                          : goals.id == "goal-5"
                          ? "EXCELENTE CREDITO"
                          : ""
                      }}</span>
                    </li>
                  </template>
                  <template v-else>
                    <li v-if="data.item.answer_goals" style="width: 300px">
                      <span>{{ data.item.answer_goals }}</span>
                    </li>
                  </template>
                </ul>
              </div>
            </template>
            <template #cell(created_at)="data">
              {{ data.item.created_at | myGlobal }}
            </template>
            <template #cell(date_income)="data">
              {{ data.item.date_income | myGlobal }}
              <FeatherIcon
                icon="ListIcon"
                class="text-primary cursor-pointer"
                size="20"
                @click="openModalHistory(data.item)"
              />
            </template>
            <!-- Column ADVISOR -->
            <template #cell(advisor_name)="data">
              <feather-icon
                id="icon"
                :title="
                  data.item.state_advisor == 1
                    ? 'Active'
                    : data.item.state_advisor == 2
                    ? 'Busy'
                    : data.item.state_advisor == 3
                    ? 'Away'
                    : 'Offline'
                "
                icon="CircleIcon"
                size="13"
                :style="
                  data.item.state_advisor == 1
                    ? 'background:green'
                    : data.item.state_advisor == 2
                    ? 'background:orange'
                    : data.item.state_advisor == 3
                    ? 'background:red'
                    : 'background:#ccc'
                "
              />
              {{ data.item.advisor_name }}
            </template>

            <template #cell(typedoc)="data">
              <span>{{
                data.item.ssn
                  ? "SSN"
                  : data.item.itin
                  ? "ITIN"
                  : data.item.other
                  ? "CPN"
                  : ""
              }}</span>
            </template>

            <template #cell(action)="data">
              <b-dropdown
                v-if="
                  data.item.user_responsible == currentUser.user_id ||
                  data.item.user_responsible == null
                "
                variant="link"
                no-caret
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="openAddSmsModal(data.item, 2)">
                  <feather-icon icon="MessageCircleIcon" class="mr-1" />
                  <span>Send SMS</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #custom-foot>
              <b-tr>
                <b-td v-if="statusSearch == 2" />
                <b-td>
                  <span style="font-size: 15px">TOTAL:</span>
                </b-td>
                <b-td v-if="statusSearch === 2">
                  <b-badge style="font-size: 15px"
                    >$ {{ totalBalance | currencyZero }}
                  </b-badge>
                </b-td>
                <b-td v-else />
                <b-td>
                  <b-badge style="font-size: 15px"
                    >$ {{ totalMonthlyPayments | currencyZero }}
                  </b-badge>
                </b-td>
                <b-td />
                <b-td />
                <b-td />
                <b-td />
                <b-td />
              </b-tr>
            </template>
          </b-table>
        </template>
      </filter-slot>
      <add-sms-modal-component
        v-if="addSmsModal"
        :client-data="clientData"
        :action-type="actionType"
        @closeModal="addSmsModal = false"
        @onAddSms="onAddSms"
      />
      <!-- derivation action -->
      <derivation-action-modal
        v-if="derivationActionModal"
        :derivation-data="derivationData"
        @send="updateRow"
        @closeModal="closeDerivationActionModal"
      />
      <!--    derivation tracking modal-->
      <derivation-tracking-modal
        v-if="derivationTrackingModal"
        :derivation-data="derivationData"
        @closeModal="closeDerivationTrackingModal"
      />
      <modal-history
        v-if="modalHistory"
        :id="idClient"
        :account_id="id_account"
        :modal-history="modalHistory"
        :name-program="program_name"
        :name-client="client_name"
        @closeModalHistory="closeModalHistory"
      />
      <sms-client-history
        v-if="showSmsHistoryModal"
        :client-account-id="currentClientAccountId"
        @close="closeSmsHistoryModal"
      />
    </b-card>
  </div>
</template>

<script>
// Table clients
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import fields from "@/views/ce-digital/sub-modules/financial/view/clients/clients.fields";

// service
// import ClientService from '@/views/credit-experts/services/clients.service'

import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";

import GlobalService from "@/service/global/index";
import FiltersClientsData from "@/views/ce-digital/sub-modules/financial/view/clients/clients.filters";

import { mapGetters, mapActions } from "vuex";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";

// modal history
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import AddSmsModalComponent from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/AddSmsModal";
import SmsClientHistory from "@/views/ce-digital/sub-modules/financial/view/clients/components/modales/SmsClientHistory.vue";
import LastPaymentItem from "@/views/ce-digital/components/LastPaymentItem.vue";

export default {
  components: {
    DerivationActionModal,
    FilterSlot,
    StatusPayment,
    StatusAccount,
    DerivationTrackingModal,
    ViewClientDetails,
    ModalHistory,
    NcrLeadsService,
    FilterStatusAccount,
    AddSmsModalComponent,
    SmsClientHistory,
    LastPaymentItem,
  },
  props: {
    statusSearch: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentClientAccountId: null,
      showSmsHistoryModal: false,
      addSmsModal: false,
      actionType: null,
      clientData: {},
      subtotal: 0,
      totalSearch: 0,
      goals: [],

      modal: {
        programs: false,
      },
      clients: null,

      filters: FiltersClientsData,
      fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      tiemposegundos: 30,
      tiempominutos: 0,
      pnttime: false,
      advisor: "",
      usersServices: [],
      items: [],
      derivationActionModal: false,
      derivationData: null,
      derivationIndex: null,
      derivationTrackingModal: null,
      idClient: "",
      program_name: "",
      client_name: "",
      modalHistory: "",
      id_account: "",
      statusNav: null,
      totalMonthlyPayments: 0,
      totalBalance: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_CLIENTS_COUNTER_HOLD: "FinancialStore/G_CLIENTS_COUNTER_HOLD",
      G_CLIENTS_COUNTER_CANCELED: "FinancialStore/G_CLIENTS_COUNTER_CANCELED",
      G_CLIENTS_COUNTER_CLOSED: "FinancialStore/G_CLIENTS_COUNTER_CLOSED",
      G_CLIENTS_COUNTER_IN_PROGRESS:
        "FinancialStore/G_CLIENTS_COUNTER_IN_PROGRESS",
      G_CLIENTS_COUNTER_POTENTIAL: "FinancialStore/G_CLIENTS_COUNTER_POTENTIAL",
      G_CLIENTS_COUNTER_STAND_BY: "FinancialStore/G_CLIENTS_COUNTER_STAND_BY",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((f) => f.visible);
    },

    paymentType: {
      get() {
        return this.filters[7].model;
      },
      set(value) {
        this.filters[7].model = value;
      },
    },

    paymentDay: {
      get() {
        return this.filters[5].model;
      },
      set(value) {
        this.filters[8].visible = value;
      },
    },
  },
  async created() {
    if (this.statusSearch === 2) {
      this.statusNav = 2;
      this.fields = this.setFieldVisibility(this.fields, "balance", true);
      this.filters[3].visible = false;
    } else {
      this.statusNav = 11;
      this.fields = this.setFieldVisibility(this.fields, "balance", false);
      this.filters[3].visible = true;
    }
    await this.usersPrograms();
    await this.usersAllPrograms();
    await this.getStatesEeuu();
    await this.getAllStatusClientAccount();
  },

  methods: {
    async changeNav(value) {
      this.statusNav = value;
    },
    openModalHistory(item) {
      this.program_name = item.program_name;
      this.client_name = item.lead_name;
      this.modalHistory = true;
      this.id_account = item.account_id;
    },
    closeModalHistory() {
      this.modalHistory = false;
    },
    async getStatesEeuu() {
      try {
        const dataState = await GlobalService.getStatesEEuu();
        this.filters[9].options = dataState.data;
        this.filter[9].options.unshift({
          state: "All",
          slug: 0,
        });
      } catch (e) {}
    },
    updateTable() {
      this.filters[0].model = null;
      this.filters[1].model = null;
      this.filters[2].model = null;
      this.filters[3].model = null;
      this.filters[4].model = null;
      this.filters[5].model = null;
      this.filters[6].model = null;
      this.filters[7].model = null;
      this.filters[8].model = null;
      this.filters[9].model = null;
      this.filters[10].model = null;
      this.filterPrincipal.model = "";
      this.$refs.refClientsList.refresh();
    },

    numberFormatted(n) {
      // This function adds comma thousand separator to a number
      const [numberPart, decimalPart] = n.toString().split(".");
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return (
        numberPart.replace(thousands, ",") +
        (decimalPart ? `.${decimalPart}` : "")
      );
    },

    async contador() {
      try {
        this.pnttime = true;
        this.updateReloj();
        await ClientService.generateRefresh();
      } catch (error) {
        console.log(error);
      }
    },
    updateReloj() {
      this.tiemposegundos = this.tiemposegundos;
      this.tiempominutos = this.tiempominutos;
      if (this.tiempominutos == 0 && this.tiemposegundos == 0) {
        this.$refs.refClientsList.refresh();
        this.pnttime = false;
        this.tiemposegundos = 59;
        this.tiempominutos = 1;
      } else {
        // Restamos un segundo al tiempo restante
        if (this.tiemposegundos == 0) {
          this.tiemposegundos = 59;
          if (this.tiempominutos != 0) {
            this.tiempominutos -= 1;
          }
        }
        this.tiemposegundos -= 1;
        // Ejecutamos nuevamente la función al pasar 1000 milisegundos (1 segundo)
        setTimeout(() => {
          this.updateReloj();
        }, 1000);
      }
    },

    async myProvider(ctx) {
      let sortBy = 5;
      let sortDirection = "desc";
      this.selectAll = false;
      if (ctx.sortBy === "lead_name") {
        sortBy = 4;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "charges") {
        sortBy = 10;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "date_activation") {
        sortBy = 9;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "type_payment") {
        sortBy = 27;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "score") {
        sortBy = 12;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "created_at") {
        sortBy = 17;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (ctx.sortBy === "fromm") {
        sortBy = 50;
        if (ctx.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      }
      try {
        const program = 3;
        if (this.currentUser.role_id == 1 || this.currentUser.role_id == 2) {
          this.advisor = this.filters[2].model;
        } else if (this.currentUser.role_id == 3) {
          this.advisor = this.currentUser.user_id;
        } else {
          this.advisor = null;
        }
        const params = {
          state_eeuu: this.filters[9].model,
          per_page: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program,
          orderby: sortBy,
          order: sortDirection,
          status: this.filters[3].model,
          advisor: this.advisor,
          type: this.filters[4].model,
          day: this.paymentDay,
          score: this.filters[6].model,
          pGoal: this.filters[8].model,
          rol_id: this.currentUser.role_id,
          typescredential: this.filters[7].model,
          hasApp: this.filters[10].model,
          status_search: this.statusNav,
          is_digital: null,
          page: ctx.currentPage,
        };
        const { data } = await amgApi.post(
          `/clients/search-clients-ce-loyal?page=${ctx.currentPage}`,
          params
        );
        data.data.map((datos) => {
          if (datos.answer_goals) {
            try {
              datos.answer_goals = JSON.parse(
                datos.answer_goals.replace(/\\/g, '"')
              );
            } catch (e) {
              if (!(e instanceof SyntaxError)) {
                throw e;
              }
            }
          }
        });

        this.subtotal = data.data.reduce(
          (acc, item) => (Number(acc) + Number(item.charges)).toFixed(2),
          0
        );

        this.totalSearch =
          data.data.length > 0 ? Number(data.data[0].sum_total).toFixed(2) : 0;

        this.items = data.data;
        this.clients = this.items;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;

        this.totalBalance = this.items[0]?.total_balance ?? 0;
        this.totalMonthlyPayments = this.items[0]?.total_monthly_payments ?? 0;

        return this.items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: 6,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        if (this.currentUser.role_id) {
          const data = await ClientService.usersPrograms(params);
          this.filters[2].label = "Advisor";
          this.filters[2].selectText = "user_name";
          this.filters[2].options = data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data; // .filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },

    openDerivationActionModal(item, index) {
      this.derivationData = item;
      this.derivationIndex = index;
      this.derivationActionModal = true;
    },
    closeDerivationActionModal() {
      this.derivationActionModal = false;
    },
    updateRow() {
      this.$set(this.items[this.derivationIndex], "is_derivated", 1);
    },
    openDerivationTrackingModal(item) {
      this.derivationData = item;
      this.derivationTrackingModal = true;
    },
    closeDerivationTrackingModal() {
      this.derivationTrackingModal = false;
    },
    async getAllStatusClientAccount() {
      this.filters[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount({
        parent_id: 5,
      });
      this.filters[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[3].options.push(...data);
    },

    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    openAddSmsModal(item, type) {
      this.clientData = {
        ...item,
        client_account_id: item.account_id,
        client_name: item.lead_name,
      };
      this.actionType = type;
      if (this.clientData.mobile !== null) {
        this.addSmsModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    onAddSms(item) {
      this.$set(item, "count_sms", Number(item.count_sms) + 1);
      this.addSmsModal = false;
    },
    closeModal() {
      this.addSmsModal = false;
    },
    openSmsHistoryModal(clientAccountId) {
      this.currentClientAccountId = clientAccountId;
      this.showSmsHistoryModal = true;
    },
    closeSmsHistoryModal() {
      this.currentClientAccountId = null;
      this.showSmsHistoryModal = false;
    },
  },

  watch: {
    paymentType(newVal) {
      if (newVal == 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
      }
    },
    statusNav(newStatusNav, oldStatusNav) {
      if (newStatusNav === oldStatusNav) return;
      this.$refs.refClientsList.refresh();
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.status_circle {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 15px !important;
}
</style>
