<template>
  <div class="text-center">
    <feather-icon
      v-if="last_payment == null"
      @click="getLastPaymentCE()"
      icon="HelpCircleIcon"
      variant="secondary"
      class="cursor-pointer"
      size="20"
    />
    <span v-if="last_payment != null && last_payment != '-'">
      {{ last_payment | myGlobal }}
    </span>
    <span v-if="last_payment == '-'"> - </span>
  </div>
</template>

<script>
import ClientService from "@/views/commons/components/clients/services/clients.services";

export default {
  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      last_payment: null,
    };
  },
  methods: {
    async getLastPaymentCE(item) {
      try {
        const { data } = await ClientService.getLastPaymentCE({
          client_account_id: this.clientAccountId,
        });
        this.last_payment = data;
        // this.$set(item, "last_payment", data);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>